<template>
  <v-row justify="center">
      <v-expansion-panel>
        <v-expansion-panel-header >
          <v-card
            :color="color"
            :dark="dark"
            flat
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                >
                  {{ title }}
                </v-card-title>

                <v-card-subtitle v-if="subtitle">
                  {{ subtitle }}
                </v-card-subtitle>
              </div>

              <v-avatar 
                v-if="pic"
                class="ma-3"
                size="100"
                tile
              >
                <v-img :src="pic" />
              </v-avatar>
            </div>
            <!-- <v-card-actions>
              <v-row justify="center">
                <v-icon>
              $expand
            </v-icon>
              </v-row>
            </v-card-actions> -->
          </v-card>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            {{ desc }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
  </v-row>
</template>

<script>
  export default {
    props: {
      name: String,
      title: String,
      pic: String,
      desc: String,
      height: Number,
      content: String,
      subtitle: String,
      color: {
        type: String,
        default: 'white',
     },
     dark: {
        type: Boolean,
        default: true,
     }
    },
    methods: {
    },
  }
</script>
