<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="12"
      >
        <v-expansion-panels
          popout
        >
          <expandable v-bind="item" />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import expandable from '../../components/expandable.vue'
  export default {
    name: 'SectionExpandables',
    components: { expandable },
    data: () => ({
      items: [
        {
          // color: 'primary',
          subtitle: 'dzjdbl zb dkquhsd lqiu dluq',
          // pic: '/img/logo-fondIndePresse.133b7800.jpg',
          title: 'Communiqué de presse',
          desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus exercitationem dolorem accusantium cum? Fugit maxime ipsa minima dolorum enim quo autem similique ad aliquid. Inventore eos odit quis tempora quae?',
          dark: false,
          // outlined: true,
          // elevation: 0,
          flat: true,
        },
        {
          color: 'info',
          pic: '/img/logo-fondIndePresse.133b7800.jpg',
          title: 'Communiqué de presse',
          desc: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus exercitationem dolorem accusantium cum? Fugit maxime ipsa minima dolorum enim quo autem similique ad aliquid. Inventore eos odit quis tempora quae?',
        },
      ],
    }),
  }
</script>
