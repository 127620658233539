import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c(VCard,{attrs:{"color":_vm.color,"dark":_vm.dark,"flat":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.subtitle)?_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1),(_vm.pic)?_c(VAvatar,{staticClass:"ma-3",attrs:{"size":"100","tile":""}},[_c(VImg,{attrs:{"src":_vm.pic}})],1):_vm._e()],1)])],1),_c(VExpansionPanelContent,[_c('div',[_vm._v(" "+_vm._s(_vm.desc)+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }